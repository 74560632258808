import layerService from '@/layers/layerService'
import { BOUNDARIES_LAYER, EDITABLE_DATA_LAYER } from '@/layers'
import { Plan } from '@workspaces/types'

export function manageBoundariesLayerVisibility(
  legend: Plan.Legend,
  updateDeckInstance: boolean,
): void {
  // legend props
  const legendBoundariesVisibility = legend.boundariesVisibility ?? true
  if (legendBoundariesVisibility) {
    layerService.showLayer(BOUNDARIES_LAYER, updateDeckInstance)
    layerService.showLayer(EDITABLE_DATA_LAYER, updateDeckInstance)
  } else {
    layerService.hideLayer(BOUNDARIES_LAYER, updateDeckInstance)
    layerService.hideLayer(EDITABLE_DATA_LAYER, updateDeckInstance)
  }
}
