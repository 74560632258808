import { Entity } from './entity.type'

export interface Country {
  iso2: string
  name: string
  id: number
}

export enum ExportFileTabs {
  AssetsWithOnlyAssetsColumns = 'assets_with_only_assets_columns',
  AssetsWithPois = 'assets_with_pois',
  AssetsWithTotalAssets = 'assets_with_total_assets',
  Assets = 'assets',
  Pois = 'pois',
  PoisAdvanced = 'pois_advanced',
}

export interface ExportFileColumn {
  id: string
  selected: boolean
}

export interface ExportFileColumnsSelection extends Entity {
  columns: ExportFileColumn[]
}

export interface ExportFileColumnFormatted extends ExportFileColumn {
  name: string
}
