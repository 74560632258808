/* eslint-disable @typescript-eslint/no-explicit-any */
import { Filter } from '../filter.type'
import { getAppMetadata } from '../../metadata/metadata'
import { AssetDataGeoJsonLayer, AssetSubFilter } from '../asset.type'
import { FilterAppliedResultAudiences } from '../plan.type'
import {
  AudienceClass,
  AudienceFeatureVersion,
  UserAudiencesGroup,
} from './audience.type'
import { getAudienceFeatureVersion } from '../../metadata/metadata.helper'
import { filterAssetsByAudienceV2 } from './audiencesV2.helper'
import { Environment } from '@workspaces/types'
import AssetsService from '@/services/assets.service'

function audiencesClassIdFormatted(audiencesRaw: any): string[][] {
  const audiences: string[][] = []
  let audienceGroup: string[] = []
  audiencesRaw.forEach((audienceGroupRaw: any) => {
    audienceGroupRaw.audiences.forEach((audienceRaw: any) => {
      const id = audienceRaw.ids[0]
      audienceGroup.push(id)
    })
    audiences.push(audienceGroup)
    audienceGroup = []
  })
  return audiences
}

async function filterAssetsByAudienceV1(
  environment: Environment.EnvironmentResolver,
  assets: AssetDataGeoJsonLayer[],
  filter: Filter,
): Promise<FilterAppliedResultAudiences> {
  const filterAudiences = filter.userAudiencesGroup

  if (filterAudiences === undefined || filterAudiences.length === 0) {
    return { assets, filterApplied: false, assetsAudience: [] }
  }

  const audiences = audiencesClassIdFormatted(filterAudiences)
  const metadata = getAppMetadata()

  if (!(typeof filter.audiencesIndex === 'number')) {
    const msg = `🛑 Audience index ${filter.audiencesIndex} is not a number. Expected number for defining filter values for audiences`
    // eslint-disable-next-line no-console
    console.error(msg)
    throw new Error(msg)
  }

  const queryParams: AssetSubFilter = {
    audiences: { audiences, index: filter.audiencesIndex },
    delivery: filter.delivery,
  }

  const filteredAssetsId =
    await AssetsService.getAssetsToShowBasedOnAudienceFilter(
      metadata,
      environment,
      queryParams,
    )
  const mapWithFilteredAssetsId: Map<string, boolean> = new Map()
  filteredAssetsId.forEach((assetId) =>
    mapWithFilteredAssetsId.set(assetId, true),
  )
  const filteredAssets = assets.filter((asset) => {
    const hit = mapWithFilteredAssetsId.get(asset.properties.id)
    return hit !== undefined
  })

  return { assets: filteredAssets, filterApplied: true, assetsAudience: [] }
}

export async function filterAssetsByAudience(
  environment: Environment.EnvironmentResolver,
  assets: AssetDataGeoJsonLayer[],
  filter: Filter,
  latestDelivery: string,
): Promise<FilterAppliedResultAudiences> {
  const audienceFeatureVersion = getAudienceFeatureVersion()
  const filterWithDeliveryUpdated = {
    ...filter,
    delivery: filter.delivery || latestDelivery,
  }
  switch (audienceFeatureVersion) {
    case AudienceFeatureVersion.V1: {
      console.debug('           🔍 Audiences [v1]')
      const assetsFiltered = await filterAssetsByAudienceV1(
        environment,
        assets,
        filterWithDeliveryUpdated,
      )
      return assetsFiltered
    }
    case AudienceFeatureVersion.V2: {
      console.debug('           🔍 Audiences [v2]')
      const assetsFiltered = await filterAssetsByAudienceV2(
        environment,
        assets,
        filterWithDeliveryUpdated,
      )
      return assetsFiltered
    }
    default: {
      const msg = `🛑 Audience feature version ${audienceFeatureVersion} not supported`
      // eslint-disable-next-line no-console
      console.error(msg)
      throw new Error(msg)
    }
  }
}

export function propagateAudienceDelivery(
  filters: Filter[],
  deliveryToInclude?: string,
): Filter[] {
  let delivery = filters[0].delivery
  if (deliveryToInclude && !delivery) {
    delivery = deliveryToInclude
  }
  const filtersUpdated = filters.map((filter) => {
    const filterUpdated = { ...filter }
    filterUpdated.delivery = delivery
    return filterUpdated
  })
  return filtersUpdated
}

export function getAudiencesIdsFromUserAudiencesGroup(
  userAudiencesGroup: UserAudiencesGroup[],
): string[] {
  const audiencesIds: string[] = []
  userAudiencesGroup.forEach((audienceGroup: UserAudiencesGroup) => {
    audienceGroup.audiences.forEach((audience: AudienceClass) => {
      audiencesIds.push(audience.ids[0])
    })
  })
  return audiencesIds
}
