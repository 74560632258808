import {
  getParamsFromFilter,
  isAudiencesQuintileInFilter,
} from '@/plan/filter/audiences-quintile.helper'
import { SimplePlanWithFilters } from '@/plan/filter/filter.type'
import AudiencesQuintileService from '@/services/audiences-quantile-browser.service'
import { AudiencesQuintile, Environment, Metadata } from '@workspaces/types'
import { propsForAudiencesQuintileLayer } from '@/layers/audiences-quintile/audiences-quintile-layer'
import layerService from '@/layers/layerService'
import { AUDIENCES_QUINTILE_TILESET_LAYER } from '@/layers/constant'
import { isNotEmptyArray } from './array.helper'
import { LayerObject } from '@/layers/audiences-quintile/audiences-quintile-layer.types'
import {
  getFilledColor,
  getLineWidth,
  getLineColor,
  filterElements,
} from '@/layers/audiences-quintile/audiences-quintile-layer.helper'

function getDataRelatedProps(
  zipCodesByQuintiles: Map<number, AudiencesQuintile.ZipCodeInfo>,
  selectedQuintiles: number[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return {
    zipCodesByQuintiles,
    selectedQuintiles,
    getFillColor: (e: LayerObject) =>
      getFilledColor(e, zipCodesByQuintiles, selectedQuintiles),
    getLineWidth: (e: LayerObject) => getLineWidth(e, zipCodesByQuintiles),
    getLineColor: (e: LayerObject) =>
      getLineColor(e, zipCodesByQuintiles, selectedQuintiles),
    getFilterValue: (e: LayerObject) => filterElements(e, zipCodesByQuintiles),
    updateTriggers: {
      getLineColor: [zipCodesByQuintiles, selectedQuintiles],
      getLineWidth: [zipCodesByQuintiles, selectedQuintiles],
      getFillColor: [zipCodesByQuintiles],
    },
  }
}

function createAudiencesQuintileLayer(
  zipCodesByQuintiles: Map<number, AudiencesQuintile.ZipCodeInfo>,
  selectedQuintiles: number[],
) {
  layerService.addLayer(
    {
      ...propsForAudiencesQuintileLayer,
      ...getDataRelatedProps(zipCodesByQuintiles, selectedQuintiles),
    },
    true,
  )
}

function updateAudiencesQuitileLayer(
  zipCodesByQuintiles: Map<number, AudiencesQuintile.ZipCodeInfo>,
  selectedQuintiles: number[],
) {
  const layerProps = layerService.getLayer(AUDIENCES_QUINTILE_TILESET_LAYER)
  if (!layerProps) {
    throw new Error(
      '🛑 Audiences-quintile layer is not found when trying to update',
    )
  }

  layerService.updateLayer(AUDIENCES_QUINTILE_TILESET_LAYER, {
    ...getDataRelatedProps(zipCodesByQuintiles, selectedQuintiles),
  })
}

function addOrUpdateAudienceLayer(
  zipCodesByQuintiles: Map<number, AudiencesQuintile.ZipCodeInfo>,
  selectedQuintiles: number[],
): void {
  console.debug('Adding or updating audience quintile layer')
  const isLayerAlreadyAddedToMap = layerService.hasLayer(
    AUDIENCES_QUINTILE_TILESET_LAYER,
  )
  if (!isLayerAlreadyAddedToMap) {
    console.debug('Creating audiences quintile layer')
    createAudiencesQuintileLayer(zipCodesByQuintiles, selectedQuintiles)
  } else {
    console.debug('Updating audiences quintile layer')
    updateAudiencesQuitileLayer(zipCodesByQuintiles, selectedQuintiles)
  }
}

export function removeAudiencesQuintileLayer(
  hideLayerInsteadOfRemoving = false,
): void {
  const isLayerAlreadyAddedToMap = layerService.hasLayer(
    AUDIENCES_QUINTILE_TILESET_LAYER,
  )
  if (isLayerAlreadyAddedToMap) {
    if (hideLayerInsteadOfRemoving) {
      layerService.hideLayer(AUDIENCES_QUINTILE_TILESET_LAYER)
    } else {
      layerService.removeLayer(AUDIENCES_QUINTILE_TILESET_LAYER)
    }
  }
}

export async function setupAudiencesQuintileLayer(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  planWithFilters: SimplePlanWithFilters,
  isSharedPlan: boolean,
): Promise<void> {
  const isLayerInstantiated = layerService.hasLayer(
    AUDIENCES_QUINTILE_TILESET_LAYER,
  )
  if (isSharedPlan && isLayerInstantiated) {
    layerService.showLayer(AUDIENCES_QUINTILE_TILESET_LAYER)
    return
  }

  const filter = planWithFilters.filters[0]
  if (!isAudiencesQuintileInFilter(filter)) {
    return
  }

  const params = getParamsFromFilter(filter)
  const zipCodesByQuintiles =
    await AudiencesQuintileService.getZipCodesByQuintile(
      meta,
      environment,
      params,
      planWithFilters.id,
    )

  const selectedQuintiles = params.audiencesQuintile.quintiles
  if (!isNotEmptyArray(selectedQuintiles)) {
    throw new Error(
      'Quintiles selection is empty when trying to render Audiences-quintile layer',
    )
  }

  addOrUpdateAudienceLayer(zipCodesByQuintiles, selectedQuintiles)
}

export function isAudienceQuintileLayerInstantianedInDeckGl(
  checkVisibility?: boolean,
): boolean {
  const layer = layerService.hasLayer(AUDIENCES_QUINTILE_TILESET_LAYER)
  if (checkVisibility === undefined || checkVisibility === false) {
    return layer
  }
  const isVisible = layerService.isVisible(AUDIENCES_QUINTILE_TILESET_LAYER)
  return layer && isVisible
}

export function getQuintileForAsset(zipCode: number): number | undefined {
  const quintiles = layerService.getLayer(AUDIENCES_QUINTILE_TILESET_LAYER)
    ?.props?.zipCodesByQuintiles
  if (!quintiles) {
    return undefined
  }
  const quintile = quintiles.get(zipCode).quintile
  return quintile
}
