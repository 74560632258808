export enum AuditEventType {
  Login = 'login',
  LoginFirstTime = 'login_first_time',
  PlanCreated = 'plan_created',
  PlanSaved = 'plan_saved',
  PlanOpened = 'plan_opened',
  PlanDeleted = 'plan_deleted',
  PlanShared = 'plan_shared',
  PlanInsights = 'plan_insights',
  PlanExported = 'plan_exported',
  PlanLoaded = 'plan_loaded',
  PlanLoadedUsage = 'plan_loaded_usage',
  BasemapChangedToGoogle = 'basemap_changed_to_google',
}

export interface AuditEvent {
  type: AuditEventType
  params: string
  user_email: string
  created_at: Date
}
