import { Environment, Metadata, Plan } from '@workspaces/types'
import { UserPermissions } from '../permissions.manager.type'
import ResolverBasic from './resolver-basic'
/**
 * 📢 No roles are defined
 */
class PermissionResolverUS extends ResolverBasic implements UserPermissions {
  canUploadCustomPOIs(): boolean {
    return true
  }

  canEditAnyCustomPOIGroup(): boolean {
    return false
  }

  permitComments(): boolean {
    return false
  }

  public canSavePlan(plan: Plan.Plan): boolean {
    const planId = plan.id
    if (!planId) {
      return true
    }
    const planOwner = plan.created_by
    return this.getName() === planOwner
  }

  canSavePlanAsPublic(): boolean {
    return true
  }

  canDeletePlan(plan: Plan.Plan): boolean {
    const planOwner = plan.created_by
    return this.getName() === planOwner
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canDeleteCustomGeoboundary(owner: string): boolean {
    return false
  }

  canDeleteCustomPOIGroup(owner: string): boolean {
    return this.isOwnerOrAdmin(owner)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canUpdateCustomPOIGroup(owner: string): boolean {
    return this.isOwnerOrAdmin(owner)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canAdminEditPlan(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canEditPlanPrice(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canBookPlanPackage(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canConfirmBookingPlanPackageByAdmin(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canConfirmBookingPlanPackageByAgency(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canFinalizeConfirmBookingPlanPackageByAdmin(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canCancelPlanPackageByAdmin(plan: Plan.Plan): boolean {
    return false
  }

  canShowWidgetUploadAssets(): boolean {
    return true
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canEditPlanPackageDates(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canEditPlanPackagePrice(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canEditPlanPackageCustomPanels(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canEditPlanPackagePanelTypes(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canEditPlanPackagePackages(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canAccessCustomizationByCountry(
    metadata: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
  ): boolean {
    return false
  }
}

export default PermissionResolverUS
