import {
  ASSETS,
  CLUSTER_ASSETS,
  EXCLUDED_ASSETS,
  EXCLUDED_CLUSTER_ASSETS,
} from '@/layers'
import layerService from '@/layers/layerService'
import { updateAssetIdLayer } from '@/layers/asset-id/asset-id-layer'
import { ASSETS_IDS_LAYER } from '@/layers/constant'
import { getters } from '@/utils/vuex'
import { GETTERS_PLAN, MODULE_NAME_PLAN } from '@/store/plan'

export function shouldAssetSimpleLayerBeVisible(
  switchLayersZoomValue: number,
  currentZoom: number,
): boolean {
  return currentZoom >= switchLayersZoomValue
}

function isExcludedLayerVisible(): boolean {
  return (
    layerService.isVisible(EXCLUDED_ASSETS) ||
    layerService.isVisible(EXCLUDED_CLUSTER_ASSETS)
  )
}

export function manageAssetsLayersVisibility(
  switchLayersZoomValue: number,
  currentZoom: number,
  viewState: unknown,
) {
  // If assets layers is hidden do nothing
  if (
    !layerService.isVisible(CLUSTER_ASSETS) &&
    !layerService.isVisible(ASSETS)
  ) {
    return
  }

  const zoomValue = currentZoom
  // console.debug('manageAssetsLayersVisibility', switchLayersZoomValue, currentZoom, zoomValue)
  layerService.setZoom(zoomValue)
  if (
    zoomValue >= switchLayersZoomValue &&
    layerService.isVisible(CLUSTER_ASSETS)
  ) {
    console.debug('🔷 Switching to Simple Assets Layer')
    layerService.hideLayer(CLUSTER_ASSETS, {}, false)
    layerService.showLayer(ASSETS, {}, false)
    if (isExcludedLayerVisible()) {
      console.debug('🔷 Switching to Simple Excluded Assets Layer')
      layerService.hideLayer(EXCLUDED_CLUSTER_ASSETS, {}, false)
      layerService.showLayer(EXCLUDED_ASSETS, {}, false)
    }
  } else if (
    zoomValue < switchLayersZoomValue &&
    layerService.isVisible(ASSETS)
  ) {
    console.debug('🔶 Switching to Cluster Asset Layer')
    // NO a flyTo en plan compartido
    layerService.hideLayer(ASSETS, {}, false)
    layerService.showLayer(CLUSTER_ASSETS, { flyToExtent: false }, false)
    if (isExcludedLayerVisible()) {
      console.debug('🔶 Switching to Cluster Excluded Assets Layer')
      layerService.hideLayer(EXCLUDED_ASSETS, {}, false)
      layerService.showLayer(EXCLUDED_CLUSTER_ASSETS, {}, false)
    }
  }

  const isAssetsIdsLayerVisible = getters(
    MODULE_NAME_PLAN,
    GETTERS_PLAN.GET_ASSETS_IDS_LAYER_VISIBILITY,
  )
  if (isAssetsIdsLayerVisible) {
    if (zoomValue >= switchLayersZoomValue) {
      updateAssetIdLayer({
        viewState,
        zoomLayerForDeclustering: switchLayersZoomValue,
      })
    } else {
      layerService.removeLayer(ASSETS_IDS_LAYER, false)
    }
  }

  layerService.updateDeckInstance()
}
