import { Credentials, executeFetch } from '@carto/ps-services'
import { Carto, Environment } from '@workspaces/types'

function getGroupsValue(value: string | undefined): string[] | undefined {
  if (value === undefined) {
    return undefined
  }

  return value.split(', ')
}

export async function getCartoMeInfo(
  environment: Environment.EnvironmentResolver,
  cartoToken: string,
): Promise<Carto.CartoMe> {
  const credentials: Credentials = { accessToken: cartoToken }
  let userInfo: Carto.CartoMe = {
    groups: (getGroupsValue(process.env.VUE_APP_TESTING_USER_GROUPS) ??
      []) as string[],
    email: 'unknown',
    name: 'unknown',
  }
  if (environment.isTesting()) {
    console.debug(
      '😈 Testing mode enabled - returning fake cartoMe content',
      userInfo,
    )
    return userInfo
  }
  try {
    userInfo = await executeFetch({
      abortController: new AbortController().signal,
      credentials,
      url: 'https://accounts.app.carto.com/users/me',
    })
  } catch (error) {
    const msg = 'User not recognized by Carto'
    console.error(msg, error)
    throw new Error(msg)
  }
  return userInfo
}
