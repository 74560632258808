import {
  FilterByPoisParamsRequestData,
  PoisFilter,
} from '../plan/filter/pois.type'
import layerService from '@/layers/layerService'
import { POI_LAYER, CUSTOM_POI_LAYER, ISOCHRONES_LAYER } from '@/layers'
import { FetchLayerDataResult } from '@deck.gl/carto/typed/api/maps-v3-client'
import { getAppMetadata } from '../plan/metadata/metadata'
import PoiService from '@/services/poi-browser.service'
import { getCustomPOIsFromDataRequest } from '../plan/filter/pois.helper'
import { commit } from '@/utils/vuex'
import { MODULE_NAME_PLAN } from '@/store/plan'
import { MUTATIONS } from '@/store/plan/constants'
import BrowserEnvironmentResolver from './environment.helper'
import { Plan } from '@workspaces/types'
import { isNonEmptyArray } from '@/utils/arrayUtils'

export function managePoisLayerVisibility(
  legend: Plan.Legend,
  filter: PoisFilter,
  updateDeckInstance: boolean,
): void {
  // legend props
  const legendPoisVisibility = legend.poisVisibility ?? true
  const legendPoisBufferVisibility = legend.poisBufferVisibility ?? true

  if (!legendPoisVisibility) {
    layerService.hideLayer(POI_LAYER, updateDeckInstance)
    layerService.hideLayer(CUSTOM_POI_LAYER, updateDeckInstance)
    layerService.hideLayer(ISOCHRONES_LAYER, updateDeckInstance)
  } else {
    if (filter && isNonEmptyArray(filter.data)) {
      if (!layerService.isVisible(POI_LAYER)) {
        if (filter.method === 'RADIUS') {
          layerService.showLayer(
            POI_LAYER,
            {
              visibleBuffer: legendPoisBufferVisibility,
            },
            updateDeckInstance,
          )
          layerService.showLayer(
            CUSTOM_POI_LAYER,
            {
              visibleBuffer: legendPoisBufferVisibility,
            },
            updateDeckInstance,
          )

          layerService.hideLayer(ISOCHRONES_LAYER, updateDeckInstance)
        } else {
          layerService.showLayer(
            POI_LAYER,
            { visibleBuffer: false },
            updateDeckInstance,
          )
          layerService.showLayer(
            CUSTOM_POI_LAYER,
            { visibleBuffer: false },
            updateDeckInstance,
          )
          layerService.showLayer(ISOCHRONES_LAYER, updateDeckInstance)
        }
      }
    } else {
      layerService.hideLayer(POI_LAYER, updateDeckInstance)
      layerService.hideLayer(CUSTOM_POI_LAYER, updateDeckInstance)
      layerService.hideLayer(ISOCHRONES_LAYER, updateDeckInstance)
    }
  }
}

export async function getCustomPOIsGroupsPoints(
  proximityParams: FilterByPoisParamsRequestData[],
): Promise<FetchLayerDataResult | null> {
  const customPOIsGroupsIds = getCustomPOIsFromDataRequest(proximityParams)
  if (customPOIsGroupsIds.length === 0) {
    return null
  }

  const metadata = getAppMetadata()
  const data = await PoiService.fetchLayer(
    metadata,
    BrowserEnvironmentResolver.getInstance(),
    customPOIsGroupsIds,
  )
  return data
}

export async function updateStateWithPOIsFilter(
  categories: string[],
  brands: string[],
  locations: string[],
  tags: string[],
): Promise<void> {
  commit(MODULE_NAME_PLAN, MUTATIONS.UPDATE_PROXIMITY_DATA, {
    categories,
    brands,
    locations,
    tags,
  })
}
