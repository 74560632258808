export function dateFormatter(
  locale: string,
  date: Date,
  endDate?: Date,
): string {
  const range = !!endDate
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  const dateTimeFormat = new Intl.DateTimeFormat(locale, options)
  if (range) {
    return dateTimeFormat.formatRange(date, endDate)
  } else {
    return dateTimeFormat.format(date)
  }
}

export function dateTimeFormatter(
  locale: string,
  date: Date,
  endDate?: Date,
): string {
  const range = !!endDate
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }
  const dateTimeFormat = new Intl.DateTimeFormat(locale, options)
  if (range) {
    return dateTimeFormat.formatRange(date, endDate)
  } else {
    return dateTimeFormat.format(date)
  }
}

export function dateShortTimeFormatter(
  locale: string,
  date: Date,
  endDate?: Date,
): string {
  const range = !!endDate
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }
  const dateTimeFormat = new Intl.DateTimeFormat(locale, options)
  if (range) {
    return dateTimeFormat.formatRange(date, endDate)
  } else {
    return dateTimeFormat.format(date)
  }
}
