export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function stringHasMultipleWords(value: string): boolean {
  return value.split(' ').length > 1
}

export function isStringInteger(value: string): boolean {
  if (stringHasMultipleWords(value)) {
    return false
  }

  const containsOnlyNumbers = /^-?\d+$/.test(value)
  if (!containsOnlyNumbers) {
    return false
  }

  const valueAsNumber = Number.parseInt(value)
  if (isNaN(valueAsNumber)) {
    return false
  }
  if (valueAsNumber % 1 !== 0) {
    return false
  }
  return true
}

export function isStringFloat(value: string): boolean {
  if (stringHasMultipleWords(value)) {
    return false
  }

  const containsOnlyNumbersAndDot = /^-?\d+([.,]\d+)+$/.test(value)
  if (!containsOnlyNumbersAndDot) {
    return false
  }

  const valueAsNumber = Number.parseFloat(value)
  if (isNaN(valueAsNumber)) {
    return false
  }
  if (valueAsNumber % 1 === 0) {
    return false
  }
  return true
}

export function debounce<T extends unknown[], U>(
  callback: (...args: T) => PromiseLike<U> | U,
  wait: number,
) {
  let timer: ReturnType<typeof setTimeout>

  return (...args: T): Promise<U> => {
    clearTimeout(timer)
    return new Promise((resolve) => {
      // eslint-disable-next-line n/no-callback-literal
      timer = setTimeout(() => resolve(callback(...args)), wait)
    })
  }
}
