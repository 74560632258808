import { Environment, Metadata, Comment } from '@workspaces/types'
import { CommentService } from '@workspaces/services'
import { getInstance as getAuth0ServiceIntance } from '@/auth/index'
import { sortData } from '@/utils/sort'
import { isNotEmptyArray } from '@/helpers/array.helper'
import { dateTimeFormatter } from '@/utils/dateFormatter'
import { Timestamp } from 'firebase/firestore'

export default {
  async getComments(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    locale: string,
    rawComments?: Comment.Comment[],
  ): Promise<Comment.Comment[]> {
    let comments: Comment.Comment[] = rawComments ?? []
    if (!rawComments) {
      comments = await CommentService.getComments(meta, environment, planId)
    }
    comments = sortData<Comment.Comment>(comments, {
      field: 'created_at',
      order: 'asc',
    })

    comments = comments.map((comment) => {
      const createdAt = comment.created_at
      if (createdAt) {
        const dateDecomposed = createdAt.toLocaleDateString()
        const timeDecomposed = createdAt
          .toLocaleTimeString([], { hour12: false })
          .split(':')
        const createdAtFormatted = `${dateDecomposed} ${timeDecomposed[0]}:${timeDecomposed[1]}`

        if (isNotEmptyArray(comment.attachments)) {
          const attachmentFormatted = comment.attachments.map((attachment) => {
            attachment.uploaded_at_formatted = dateTimeFormatter(
              locale,
              (attachment.uploaded_at as Timestamp).toDate(),
            )
            return attachment
          })
          return {
            ...comment,
            created_at_formatted: createdAtFormatted,
            attachments: attachmentFormatted,
          }
        }
        return { ...comment, created_at_formatted: createdAtFormatted }
      }
      return { ...comment, created_at_formatted: 'unknown' }
    })

    return comments
  },

  async getCommentsCount(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
  ): Promise<number> {
    const comments: number = await CommentService.getCommentsCount(
      meta,
      environment,
      planId,
    )

    return comments
  },

  async createComment(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    comment: Comment.Comment,
  ): Promise<string> {
    const auth0Instance = getAuth0ServiceIntance()
    const userId = auth0Instance.getUser()
    const userRole = auth0Instance.getRole()
    comment.created_by = userId
    comment.created_by_role = userRole

    const id = await CommentService.createComment(meta, environment, comment)
    return id
  },

  async addAttachmentToComment(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    commentId: string,
    attachment: Comment.AttachmentManagement,
  ): Promise<void> {
    const auth0Instance = getAuth0ServiceIntance()
    const userId = auth0Instance.getUser()
    const attachmentData: Comment.Attachment = {
      ...attachment,
      uploaded_at: new Date(),
      uploaded_by: userId,
    }

    await CommentService.addAttachmentToComment(
      meta,
      environment,
      commentId,
      attachmentData,
    )
  },

  async markCommentAsRead(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    commentId: string,
  ): Promise<void> {
    const auth0Instance = getAuth0ServiceIntance()
    const userId = auth0Instance.getUser()
    await CommentService.markCommentAsRead(meta, userId, environment, commentId)
  },

  async markAllPlanCommentsAsRead(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
  ): Promise<void> {
    const auth0Instance = getAuth0ServiceIntance()
    const userId = auth0Instance.getUser()
    await CommentService.markAllPlanCommentsAsRead(
      meta,
      userId,
      environment,
      planId,
    )
  },

  async markCommentAsNew(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    commentId: string,
  ): Promise<void> {
    const auth0Instance = getAuth0ServiceIntance()
    const userId = auth0Instance.getUser()
    await CommentService.markCommentAsNew(meta, userId, environment, commentId)
  },

  async markAllPlanCommentsAsNew(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
  ): Promise<void> {
    const auth0Instance = getAuth0ServiceIntance()
    const userId = auth0Instance.getUser()
    await CommentService.markAllPlanCommentsAsNew(
      meta,
      userId,
      environment,
      planId,
    )
  },

  async getAllNewComments(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    plans: { planId: string; planName: string; createdBy: string }[],
  ): Promise<Comment.QuickAccessComment[]> {
    const userId = getAuth0ServiceIntance().getUser()

    const comments: Comment.Comment[] = await CommentService.getAllNewComments(
      meta,
      environment,
      plans,
      userId,
    )

    const plansById: Map<string, { name: string; createdBy: string }> =
      new Map()
    plans.forEach((plan) => {
      plansById.set(plan.planId, {
        name: plan.planName,
        createdBy: plan.createdBy,
      })
    })

    const commentsByPlan: Map<string, Comment.Comment[]> = new Map()
    comments.forEach((comment) => {
      const planComments = commentsByPlan.get(comment.plan_id)
      if (planComments) {
        planComments.push(comment)
      } else {
        commentsByPlan.set(comment.plan_id, [comment])
      }
    })

    const quickAccessComments: Comment.QuickAccessComment[] = []
    Array.from(commentsByPlan.entries()).forEach(([planId, comments]) => {
      const planDescription = plansById.get(planId)
      if (planDescription) {
        const latestComment = comments.reduce((latest: Date, comment) => {
          if (comment.created_at && comment.created_at > latest) {
            return comment.created_at
          }
          return latest
        }, new Date(0))

        const quickAccessComment: Comment.QuickAccessComment = {
          planName: planDescription.name,
          planId,
          comments: comments.length,
          latestComment,
          created_by: planDescription.createdBy,
        }
        quickAccessComments.push(quickAccessComment)
      }
    })

    const quickAccessCommentsSorted = quickAccessComments.sort(
      (a, b) => b.latestComment.getTime() - a.latestComment.getTime(),
    )

    return quickAccessCommentsSorted
  },

  async getLatestPlanComments(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
  ): Promise<Comment.SubscribedPlanComment[]> {
    const res: Comment.SubscribedPlanComment[] =
      await CommentService.getLatestPlanComments(meta, environment)
    return res
  },
}
