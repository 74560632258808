import {
  Metadata,
  SharedPlan,
  Plan,
  Asset,
  Environment,
  Poi,
  Isochrone,
  AudiencesQuintile,
} from '@workspaces/types'
import { SharePlanService } from '@workspaces/services'
import { sendInfoMessageToUser } from '@/helpers/message/message.helper'
import { deserializePlan } from '@/helpers/plan.helper'
import { Geometry } from '@/plan/geometries/geoboundary.type'
import { convertPointsToGeoJsonPoint } from '@/layers/custom-pois/custom-pois.helper'
import { FetchLayerDataResult } from '@deck.gl/carto/typed/api/maps-v3-client'
import { FORMATS } from '@deck.gl/carto/typed'
import { Md5 } from 'ts-md5'
import {
  isPackagagesFeatureEnabled,
  sharePlanIsExpiracyEnabled,
} from '@/plan/metadata/metadata.helper'
export default {
  async isPasswordRequired(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
  ): Promise<SharedPlan.SharedPlanState> {
    const appVersion = environment.isTesting()
      ? meta.flavour_testing
      : meta.flavour
    const response: SharedPlan.SharedPlanState =
      await SharePlanService.isPasswordRequired(
        meta,
        environment,
        planId,
        appVersion,
      )
    return response
  },

  async createSharedPlan(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
    showPrice = false,
    expiracyDate: Date | undefined,
  ): Promise<string> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      password: password || undefined,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      expiracyDate: expiracyDate?.toISOString() || undefined,
    }

    if (isPackagagesFeatureEnabled(meta)) {
      params.package = {
        showPrice,
      }
    }

    if (!sharePlanIsExpiracyEnabled(meta)) {
      params.expiracyDate = undefined
    }

    const response = await SharePlanService.createSharePlan(
      meta,
      environment,
      params,
    )
    return response
  },

  async getAssets(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
    assetsOffset?: number,
    token?: string,
    iv?: string,
  ): Promise<SharedPlan.GeoJsonAsset[] | SharedPlan.RemoteAssetResolution> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
      assetsOffset: assetsOffset || undefined,
      token: token || undefined,
      iv: iv || undefined,
    }
    try {
      const response = await SharePlanService.getAssets(
        meta,
        environment,
        params,
      )
      return response
    } catch (error) {
      const errorTyped = error as Error
      sendInfoMessageToUser({
        titleLocaleKey: '',
        msgLocaleKey: '',
        title: 'Error loading plan',
        msg: errorTyped.message,
      })
      console.error(error)
      if (assetsOffset === 0) {
        return { count: 0, assets: [] }
      }
      return []
    }
  },

  async getPlan(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
  ): Promise<Plan.Plan> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
    }
    const response = await SharePlanService.getPlan(meta, environment, params)
    const plan = deserializePlan(response)
    return plan
  },

  async getGeometries(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
  ): Promise<Geometry[]> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
    }
    const response = await SharePlanService.getGeometries(
      meta,
      environment,
      params,
    )
    return response
  },

  async getSharedPlanPoisWithIsochrone(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
    filter: Isochrone.IsochronesFilter,
  ): Promise<Isochrone.IsochroneGeometry[]> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
      isochronesFilter: filter,
    }
    const response = await SharePlanService.getSharedPlanPoisWithIsochrone(
      meta,
      environment,
      params,
    )
    return response
  },

  async getSharedActiveCustomPoisGroups(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    customPoisIds: string[],
  ): Promise<Poi.CustomPOIsGroupId[]> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      customPoisIds,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
    }
    const response = await SharePlanService.getSharedActiveCustomPoisGroups(
      meta,
      environment,
      params,
    )
    return response
  },

  async getCustomPOIs(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
    activeSubPlanFiltersIndex: number,
  ): Promise<Partial<FetchLayerDataResult>> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
    }
    const data = await SharePlanService.getCustomPOIs(
      meta,
      environment,
      params,
      activeSubPlanFiltersIndex,
    )
    const dataFormatted = convertPointsToGeoJsonPoint(data)
    const dataToReturn = {
      format: FORMATS.GEOJSON,
      data: dataFormatted,
    }
    return dataToReturn
  },

  async getAssetById(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
    assetId: string,
  ): Promise<Partial<Asset.AssetFull>[]> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
    }
    const data: Partial<Asset.AssetFull> = await SharePlanService.getAssetById(
      meta,
      environment,
      params,
      assetId,
    )

    return [data]
  },

  async getInsights(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
  ): Promise<SharedPlan.SharedPlanInsights> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
    }
    const data: SharedPlan.SharedPlanInsights =
      await SharePlanService.getInsights(meta, environment, params)
    return data
  },

  async getSharedPlanContentFromPlanId(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
  ): Promise<SharedPlan.SharedPlan | null> {
    const plan = await SharePlanService.getSharedPlanContentFromPlanId(
      meta,
      environment,
      planId,
    )
    return plan
  },

  async updateSharedPlanContent(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    sharedPlan: SharedPlan.SharedPlan,
  ): Promise<void> {
    const sharedPlanId = sharedPlan.id
    if (!sharedPlanId) {
      throw new Error(
        'Shared plan id is missing, and is required for update operation',
      )
    }
    if (sharedPlan.passwordRequire) {
      const password = sharedPlan.password
      if (!password) {
        throw new Error('Password is required to update shared plan')
      }
      sharedPlan.originalPassword = password
      sharedPlan.password = Md5.hashStr(password)
    } else {
      delete sharedPlan.password
      delete sharedPlan.originalPassword
    }
    await SharePlanService.updateSharedPlanContent(
      meta,
      environment,
      sharedPlanId,
      sharedPlan,
    )
  },

  async getSharedPlanAudiencesQuintiles(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
  ): Promise<AudiencesQuintile.ZipCodesByQuintile[]> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
    }
    try {
      const response = await SharePlanService.getSharedPlanAudiencesQuintiles(
        meta,
        environment,
        params,
      )
      return response
    } catch (error) {
      const errorTyped = error as Error
      sendInfoMessageToUser({
        titleLocaleKey: '',
        msgLocaleKey: '',
        title: 'Error loading plan',
        msg: errorTyped.message,
      })
      console.error(error)
      return []
    }
  },

  async getSharedPlanAdvanceOptions(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    planId: string,
    password: string | undefined,
    isForExport: boolean,
  ): Promise<SharedPlan.AdvanceOptions> {
    const params: SharedPlan.CreateSharedPlanParameters = {
      planId,
      appVersion: environment.isTesting() ? meta.flavour_testing : meta.flavour,
      password: password || undefined,
    }
    const response = await SharePlanService.getSharedPlanAdvanceOptions(
      meta,
      environment,
      params,
      isForExport,
    )
    return response
  },
}
