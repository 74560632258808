export enum Role {
  PowerUser = 'power_user',
  Admin = 'admin',
  User = 'user',
  Unknown = 'unknown',
  Anonymous = 'anonymous',
}

export enum PermissionResolver {
  US = 'US',
  EU = 'EU',
  FI = 'FI',
}

export interface RolePayloadForFI {
  test: string
  production: string
}

export enum AppVersion {
  'Unknown' = 'Unknown',
  'EU' = 'EU',
  'Test_EU' = 'Test_EU',
  'US' = 'US',
  'Test_US' = 'Test_US',
  'FI' = 'FI',
  'Test_FI' = 'Test_FI',
}

export interface FirebaseTokenCustomClaims {
  app: AppVersion
  email: string
  role: Role
  roles: string[]
}
