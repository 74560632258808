import {
  AssetDataGeoJsonLayer,
  AssetDataGeoJsonLayerMediaType,
} from '@/plan/filter/asset.type'
import { Metadata, Plan, SharedPlan } from '@workspaces/types'
import { defaultProductSelectionInLegend } from '@/plan/metadata/metadata.helper'
import { MODEL_LEGEND } from '@/store/plan/state'

export function filterAssetsByMediaType(
  metadata: Metadata.AppMetadata,
  assets: AssetDataGeoJsonLayer[],
  mediaTypeIds: number[],
  filterByProperty: string,
): AssetDataGeoJsonLayer[] | null {
  const filterData = assets.length > 0 && mediaTypeIds?.length > 0
  if (!filterData) {
    return null
  }

  const productClassPropertySelected =
    filterByProperty ?? defaultProductSelectionInLegend(metadata)
  const filteredDataIncludingLegendFilter = assets.filter(
    (asset) =>
      !mediaTypeIds.includes(
        asset.properties[
          productClassPropertySelected as keyof AssetDataGeoJsonLayerMediaType
        ],
      ),
  )
  return filteredDataIncludingLegendFilter
}

export function prepareLegendInPlan(
  plan: Plan.Plan,
  advanceOptions: SharedPlan.AdvanceOptions | undefined,
): Plan.Plan {
  if (!advanceOptions) {
    return plan
  }

  const legend: Plan.Legend =
    plan.legend ?? (MODEL_LEGEND() as unknown as Plan.Legend)

  if (advanceOptions.layerSelection.enabled) {
    console.debug('🟪 Shared Plan: Adpating legend to shared plan parameters')
    if (!advanceOptions.layerSelection.heatmap) {
      legend.heatmapAssetsVisibility = false
    }
    if (!advanceOptions.layerSelection.pois) {
      legend.poisVisibility = false
      legend.poisBufferVisibility = false
    }
    if (!advanceOptions.layerSelection.geoboundaries) {
      legend.boundariesVisibility = false
    }
  }
  return { ...plan, legend }
}
