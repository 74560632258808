export enum MediaTypeFeatureVersion {
  V1 = 1,
  V2 = 2,
}

export interface MediaTypesNames {
  l1panelclass: string
  l2product: string
  l3subproduct: string
  l4: string
  l5: string
  l6: string
  l7: string
  l8: string
  l9: string
}

export const LEVELS: (keyof MediaTypesNames)[] = [
  'l1panelclass',
  'l2product',
  'l3subproduct',
  'l4',
  'l5',
  'l6',
  'l7',
  'l8',
  'l9',
]

export type MediaTypeValues = {
  [key in keyof MediaTypesNames]: Map<number, string>
}

export interface MediaTypeOptionsProps {
  level: keyof MediaTypesNames
  name: string
  countryId: number
}
export interface MediaTypeOptions extends MediaTypeOptionsProps {
  options: Map<number, string>
}

export interface MediaTypeOptionsFormatted extends MediaTypeOptionsProps {
  options: Array<{ id: number; name: string }>
}

export type MediaTypeLevelOptions = {
  [key in keyof MediaTypesNames]: MediaTypeOptions
}

export type CountryByMediaTypeLevelOptions = Map<number, MediaTypeLevelOptions>
