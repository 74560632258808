import { Environment, Metadata, Plan } from '@workspaces/types'
import BrowserEnvironmentResolver from './environment.helper'
import PlanService from '@/services/plan.service'
import { commit, getters } from '@/utils/vuex'
import { MODULE_NAME_PLAN } from '@/store/plan'
import { GETTERS, MUTATIONS } from '@/store/plan/constants'
import {
  getRealtimeReactionForPlans,
  isCommentsFunctionalityEnabled,
  isPackagagesFeatureEnabled,
  isPlansRealtimeFeatureEnabled,
} from '@/plan/metadata/metadata.helper'
import { subscribeToPlans as subscribeToPlansService } from '@workspaces/services'
import { getAppMetadata } from '@/plan/metadata/metadata'
import { getInstance as getAuth0ServiceInstance } from '@/auth/index'
import { updateQuickAcessPlansComments } from './comment.helper'

function setClientNameForPlans(
  meta: Metadata.AppMetadata,
  plans: Plan.Plan[],
  clients: Record<string, string>,
): Plan.Plan[] {
  if (!isPackagagesFeatureEnabled(meta)) {
    return plans
  }
  return plans.map((plan) => {
    const clientName = plan.client ? clients[plan.client] : ''
    return {
      ...plan,
      clientName,
    }
  })
}

async function fetchMyPlans(
  userId: string,
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): Promise<void> {
  try {
    const clients = getters(MODULE_NAME_PLAN, GETTERS.GET_PLANS_CLIENTS)
    const plans = await PlanService.getPlansByUserId(meta, environment, userId)
    let plansFormatted: Plan.Plan[] = plans.map((p: Plan.Plan) => {
      return {
        ...p,
        modified: p.modified_at?.toISOString(),
        user: {
          id: p.created_by,
          name: p.created_by.split('@')[0],
        },
      }
    })
    plansFormatted = setClientNameForPlans(meta, plansFormatted, clients)
    commit(MODULE_NAME_PLAN, MUTATIONS.SET_MY_PLANS, plansFormatted)
    console.debug('📒 My plans: ', plansFormatted.length)
  } catch (error) {
    commit(MODULE_NAME_PLAN, MUTATIONS.SET_MY_PLANS, [])
  }
}

async function fetchPublicPlans(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): Promise<void> {
  try {
    const clients = getters(MODULE_NAME_PLAN, GETTERS.GET_PLANS_CLIENTS)
    const plans = await PlanService.getPublicPlans(meta, environment)
    let plansFormatted: Plan.Plan[] = plans.map((p: Plan.Plan) => {
      return {
        ...p,
        modified: p.modified_at?.toISOString(),
        user: {
          id: p.created_by,
          name: p.created_by.split('@')[0],
        },
      }
    })
    plansFormatted = setClientNameForPlans(meta, plansFormatted, clients)
    commit(MODULE_NAME_PLAN, MUTATIONS.SET_PUBLIC_PLANS, plansFormatted)
    console.debug('📒 Public plans: ', plansFormatted.length)
  } catch (error) {
    commit(MODULE_NAME_PLAN, MUTATIONS.SET_PUBLIC_PLANS, [])
  }
}

async function fetchPlans(
  userId: string,
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): Promise<void> {
  await Promise.all([
    fetchMyPlans(userId, meta, environment),
    fetchPublicPlans(meta, environment),
  ])
}

export async function fetchPlansVisibleByUser(): Promise<void> {
  console.debug('📒 Fetching plans visible by user')
  const userId = getAuth0ServiceInstance().getUser()
  const meta = getAppMetadata()
  const environment = BrowserEnvironmentResolver.getInstance()
  await fetchPlans(userId, meta, environment)
  // If comments related to plans is active, we also have to update comments
  if (isCommentsFunctionalityEnabled(meta)) {
    console.debug(
      '📒 Comments are enabled, updating comments for visible plans by user',
    )
    await updateQuickAcessPlansComments()
  }
}

export function getFetchedPlansForComments(): Plan.PlanForCommentFetching[] {
  const userPlans = getters(MODULE_NAME_PLAN, GETTERS.GET_MY_PLANS)
  const publicPlans = getters(MODULE_NAME_PLAN, GETTERS.GET_PUBLIC_PLANS)
  const uniquePlans: Plan.PlanForCommentFetching[] = []
  const plans = [...userPlans, ...publicPlans]
  plans.forEach((plan) => {
    const planForCommentFetching: Plan.PlanForCommentFetching = {
      planId: plan.id,
      planName: plan.name,
      createdBy: plan.created_by,
    }
    if (!uniquePlans.some((p) => p.planId === plan.id)) {
      uniquePlans.push(planForCommentFetching)
    }
  })

  return uniquePlans
}

export async function subscribeToPlans(
  meta: Metadata.AppMetadata,
  environment: BrowserEnvironmentResolver,
): Promise<void> {
  if (!isPlansRealtimeFeatureEnabled(meta)) {
    return
  }
  const isSubscribed = getters(
    MODULE_NAME_PLAN,
    GETTERS.GET_IS_SUBSCRIBED_TO_PLANS,
  )
  if (isSubscribed) {
    console.warn(
      'Already subscribed to plans. This should be not happening, please check how this is being called',
    )
    console.debug('📒 ✋ Already subscribed to plans. Nothing to do')
    return
  }
  console.debug('📒📒📒📒 Subscribing to plans 📒📒📒📒')
  const debounceDelay = getRealtimeReactionForPlans(meta)
  console.debug('📒 Debounce delay for plans in seconds: ', debounceDelay)
  const unscribeFunction = await subscribeToPlansService(
    meta,
    environment,
    debounceDelay,
    fetchPlansVisibleByUser,
  )
  window.addEventListener('beforeunload', () => {
    if (unscribeFunction) {
      console.debug('📒 ✅ Unsubscribing from plans before closing')
      unscribeFunction()
      commit(MODULE_NAME_PLAN, MUTATIONS.SET_IS_SUBSCRIBED_TO_PLANS, false)
    }
  })
  commit(MODULE_NAME_PLAN, MUTATIONS.SET_IS_SUBSCRIBED_TO_PLANS, true)
}
