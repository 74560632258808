import { Entity, Insight, Isochrone } from '.'

export enum Action {
  Share = 'share',
  Assets = 'load_assets',
  AssetsPagination = 'load_assets_pagination',
  Plan = 'load_plan',
  Geoboundary = 'geoboundary_geometries',
  CustomPOIs = 'custom_POIs',
  Asset = 'asset_details',
  Insights = 'insights',
  PasswordRequired = 'password_required',
  SharedExportPoisAssets = 'shared_export_pois_assets',
  SharedExportAllPois = 'shared_export_all_pois',
  SharedExportAudiencesAssets = 'shared_export_audiences_assets',
  SharedExportAssetsPOIsDistance = 'shared_export_assets_pois_distance',
  ExistCustomPOIsGroups = 'exist_custom_pois_groups',
  Isochrones = 'isochrones',
  AudiencesQuintiles = 'audiences_quintiles',
  AdvanceOptions = 'advance_options',
  AllMediaTypeOptions = 'all_media_type_options',
}

export interface SharePlanProperties {
  id: string
  password?: string
}

export interface CreateSharedPlanParameters {
  planId: string
  appVersion: string
  password?: string
  assetsOffset?: number
  token?: string
  iv?: string
  package?: {
    showPrice: boolean
  }
  customPoisIds?: string[]
  isochronesFilter?: Isochrone.IsochronesFilter
  expiracyDate?: string
}

export interface AssetProperties {
  address: string
  external_panel_id: string
  geom: never
  id: string
  internal_panel_id: string
  impressions: number
  latitude: number
  longitude: number
  name: string
  l1_panel_class: string
  l1panelclass_id: number
  l2product_id: number
  l2_product: string
  state_id: number
  dmas_id: number
  congresional_district_id: number
  cbsa_id: number
  county_id: number
  city_id: string
  zip_code_id: string
  zip_code_name: string
  ad_region_text: string
  title: string
  excluded?: boolean
}

export interface GeoJsonAsset {
  type: 'Feature'
  geometry: {
    coordinates: [number, number]
    type: 'Point'
  }
  properties: AssetProperties
}

export interface RemoteAssetResolution {
  count: number
  assets: GeoJsonAsset[]
  token?: string
  iv?: string
}

export interface SharedPlanInsights {
  demographics: Insight.InsightResult[]
  topindex: Insight.InsightResult[]
  overview: Insight.InsightResult[]
  impressions: Insight.InsightImpressionsResult[]
}

export interface AdvanceOptionsExportColumnSelection {
  id: string
  name: string
  selected: boolean
}

export interface AdvanceOptions {
  exportSelection: {
    enabled: boolean
    frames: boolean
    pois: boolean
    proximity: boolean
    frameColumns: AdvanceOptionsExportColumnSelection[] | null
    poisColumns: AdvanceOptionsExportColumnSelection[] | null
    proximityColumns: AdvanceOptionsExportColumnSelection[] | null
  }
  mapViewOnly: boolean
  layerSelection: {
    enabled: boolean
    frames: boolean
    pois: boolean
    heatmap: boolean
    geoboundaries: boolean
  }
}

export interface SharedPlan extends Entity.Entity, Entity.Auditable {
  planId: string
  package?: {
    showPrice: boolean
  }
  passwordRequire: boolean
  originalPassword?: string
  url: string
  expiracyDate: Date
  createdByUserEmail: string
  creationDate: Date
  password?: string
  enabled: boolean
  advanceOptions?: AdvanceOptions
}

export interface CreateSharePlanBodyParams {
  password?: string
  showPrice?: 'true' | 'false'
  isochronesFilter?: Isochrone.IsochronesFilter
  expiracyDate?: string
}

export interface IsPasswordRequired {
  passwordRequired: boolean
}

export interface SharedPlanState {
  isActive: boolean
  isPasswordRequired: boolean
}

export interface SharePlanAdvanceOptionsVisibility {
  advance_options: boolean
  map: {
    enabled: boolean
    map_view_only: boolean
    layers_selection: boolean
  }
  export: boolean
}

export const DEFAULT_SHARED_PLAN_ADVANCE_OPTIONS: AdvanceOptions = {
  mapViewOnly: false,
  layerSelection: {
    enabled: true,
    frames: true,
    pois: true,
    heatmap: true,
    geoboundaries: true,
  },
  exportSelection: {
    enabled: true,
    frames: true,
    pois: true,
    proximity: true,
    frameColumns: null,
    poisColumns: null,
    proximityColumns: null,
  },
}
