/** * DropDown.vue **/ /* template import */
<template src="./drop-down.html"></template>
/* style import */
<style scoped lang="scss" src="./drop-down.scss"></style>
<script>
import clickOutside from '@/directives/clickOutside'

export default {
  name: 'DropDown',
  components: {},
  directives: {
    clickOutside,
  },
  props: {
    trigger: {
      type: Boolean,
      default: true,
      required: false,
    },
    expanded: {
      type: Boolean,
      required: false,
    },
    height: {
      type: String,
      default: 'auto',
      required: false,
    },
    clickOutside: {
      type: Boolean,
      default: true,
    },
    testid: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      open: false,
    }
  },
  mounted() {
    this.open = !!this.expanded
  },
  computed: {
    clickOutsideConfig() {
      return {
        handler: this.tryClose,
        disabled: !this.clickOutside,
      }
    },
  },
  methods: {
    toggle() {
      if (this.trigger !== false) {
        this.open = !this.open
        this.$emit('toggle', this.open)
      }
    },
    tryClose() {
      if (this.clickOutside) {
        this.close()
      }
    },
    close() {
      this.open = false
    },
    enter(element) {
      element.style.height = 'auto'
      const height = getComputedStyle(element).height
      element.style.height = 0
      // eslint-disable-next-line
      window.getComputedStyle(element).height
      setTimeout(() => {
        element.style.height = this.height === 'auto' ? height : this.height
      })
    },
    afterEnter(element) {
      element.style.height = this.height
    },
    leave(element) {
      const height = getComputedStyle(element).height
      element.style.height = height
      // eslint-disable-next-line
      window.getComputedStyle(element).height
      setTimeout(() => {
        element.style.height = 0
      })
    },
  },
  watch: {
    expanded(value) {
      this.open = value
    },
  },
}
</script>
