export interface LayerObject {
  properties: {
    name: number
  }
}

const opacityForSelectedQuintiles = 200
export const QuintileColors: Uint8Array[] = [
  new Uint8Array([255, 160, 160, opacityForSelectedQuintiles]), // Quintile 1 background: rgb(255, 160, 160) rgb(255, 120, 120);
  new Uint8Array([255, 216, 176, opacityForSelectedQuintiles]), // Quintile 2 background: rgba(255, 216, 176) rgba(255, 203, 157);
  new Uint8Array([255, 255, 176, opacityForSelectedQuintiles]), // Quintile 3 background: rgba(255, 255, 176) rgba(255, 247, 125);
  new Uint8Array([224, 255, 176, opacityForSelectedQuintiles]), // Quintile 4 background: rgba(224, 255, 176) rgba(205, 255, 151);
  new Uint8Array([240, 245, 255, opacityForSelectedQuintiles]), // Quintile 5 background: rgba(240, 245, 255) rgba(210, 222, 255);
  new Uint8Array([255, 255, 255, opacityForSelectedQuintiles]), // Unselected background: rgba(255, 255, 255) rgba(175, 175, 175);
]

// const opacityForSelectedQuintilesFill = 100
export const QuintileFillColors: Uint8Array[] = [
  new Uint8Array([255, 160, 160, opacityForSelectedQuintiles]), // Quintile 1 background: rgb(255, 160, 160) rgb(255, 120, 120);
  new Uint8Array([255, 216, 176, opacityForSelectedQuintiles]), // Quintile 2 background: rgba(255, 216, 176) rgba(255, 203, 157);
  new Uint8Array([255, 255, 176, opacityForSelectedQuintiles]), // Quintile 3 background: rgba(255, 255, 176) rgba(255, 247, 125);
  new Uint8Array([224, 255, 176, opacityForSelectedQuintiles]), // Quintile 4 background: rgba(224, 255, 176) rgba(205, 255, 151);
  new Uint8Array([240, 245, 255, opacityForSelectedQuintiles]), // Quintile 5 background: rgba(240, 245, 255) rgba(210, 222, 255);
  new Uint8Array([255, 255, 255, opacityForSelectedQuintiles]), // Unselected background: rgba(255, 255, 255) rgba(175, 175, 175);
]
// export const QuintileFillColors: Uint8Array[] = [
//   new Uint8Array([255, 0, 0, opacityForSelectedQuintilesFill]),
//   new Uint8Array([255, 128, 0, opacityForSelectedQuintilesFill]),
//   new Uint8Array([255, 255, 0, opacityForSelectedQuintilesFill]),
//   new Uint8Array([0, 255, 0, opacityForSelectedQuintilesFill]),
//   new Uint8Array([0, 128, 255, opacityForSelectedQuintilesFill]),
//   new Uint8Array([175, 175, 175, opacityForSelectedQuintilesFill]),
// ]

const opacityForSelectedQuintilesLine = 255
const opacityForUnselectedQuintilesLine = 102
export const QuintileLineColors: Uint8Array[] = [
  new Uint8Array([255, 120, 120, opacityForSelectedQuintilesLine]), // Quintile 1 background: rgb(255, 120, 120);
  new Uint8Array([255, 203, 157, opacityForSelectedQuintilesLine]), // Quintile 2 background: rgba(255, 203, 157);
  new Uint8Array([255, 247, 125, opacityForSelectedQuintilesLine]), // Quintile 3 background: rgba(255, 247, 125);
  new Uint8Array([205, 255, 151, opacityForSelectedQuintilesLine]), // Quintile 4 background: rgba(205, 255, 151);
  new Uint8Array([210, 222, 255, opacityForSelectedQuintilesLine]), // Quintile 5 background: rgba(210, 222, 255);
  new Uint8Array([175, 175, 175, opacityForUnselectedQuintilesLine]), // Unselected background: rgba(175, 175, 175);
]

export const TransparentColor = new Uint8Array([0, 0, 0, 0])

export const LineColors = {
  selected: new Uint8Array([242, 111, 33, 255]),
  unSelected: new Uint8Array([255, 255, 255, 255]),
}
