import { Metadata, Carto, Environment } from '@workspaces/types'

function getCountryIdsAcceptedFromToken(
  metadata: Metadata.AppMetadata,
  userInfo: Carto.CartoMe,
): number[] {
  const countries: Metadata.Country[] = metadata.countries
  const defaultCountriesForUser = userInfo.groups ?? []
  const matchingCountriesIds: number[] = []
  countries.forEach((country) => {
    if (
      country.carto_me_value &&
      defaultCountriesForUser.includes(country.carto_me_value)
    ) {
      matchingCountriesIds.push(country.id)
    }
  })

  return matchingCountriesIds
}

function getDefaultCountriesId(metadata: Metadata.AppMetadata): number[] {
  const defaultCountries =
    metadata.plan_filters.widget_countries.default_selection_iso3
  const countries = metadata.countries
  return defaultCountries.map((countryIso3) => {
    const country = countries.find((country) => country.iso3 === countryIso3)
    if (!country) {
      throw new Error(`Country ${countryIso3} not found. Check metadata`)
    }
    return country.id
  })
}

export async function getPresetCountriesForUser(
  metadata: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  userInfo: Carto.CartoMe,
): Promise<number[]> {
  if (userInfo.groups?.length === 0) {
    return getDefaultCountriesId(metadata)
  }

  const presetCountries = getCountryIdsAcceptedFromToken(metadata, userInfo)

  if (presetCountries.length === 0) {
    return getDefaultCountriesId(metadata)
  }
  return presetCountries
}
