import { Comment, Metadata, Plan } from '@workspaces/types'
import { nanoid } from 'nanoid'
import CommentsService from '@/services/comments.service'
import BrowserEnvironmentResolver from './environment.helper'
import { getAppMetadata } from '@/plan/metadata/metadata'
import { MODULE_NAME_PLAN } from '@/store/plan'
import { GETTERS, MUTATIONS } from '@/store/plan/constants'
import { commit, getters } from '@/utils/vuex'
import { getFetchedPlansForComments } from './plans.helper'
import { subscribeToComments as subscribeToCommentsService } from '@workspaces/services'
import {
  getRealtimeReactionForQuickAccessComments,
  isQuickAccessCommentEnabled,
} from '@/plan/metadata/metadata.helper'

export function isAttachmentNameUnique(
  attachments: Comment.Attachment[],
  fileName: string,
): boolean {
  const attachmentsWithSameName = attachments.some(
    (attachment) => attachment.humanName === fileName,
  )
  return attachmentsWithSameName
}

export function getCloudNameForAttachment(
  commentId: string,
  fileName: string,
): string {
  const uuid = nanoid()
  const cloudName = `${commentId}-${fileName}-${uuid}`
  return cloudName
}

export function getCommentCounts(
  comments: Comment.Comment[],
  user: string,
): {
  totalComments: number
  newComments: number
} {
  const totalComments = comments.length
  const newComments = comments.filter((comment) =>
    isNewComment(comment, user),
  ).length

  return { totalComments, newComments }
}

export function isNewComment(comment: Comment.Comment, user: string): boolean {
  if (comment.created_by === user) {
    return comment.newForCreator ?? false
  }
  return comment.new ?? false
}

export function filterCommentsBasedOnOptions(
  comments: Comment.Comment[],
  options?: {
    onlyNew?: boolean
    onlyMyMessages?: { userName: string; filterByMe: boolean }
  },
): Comment.Comment[] {
  if (!options) {
    return comments
  }

  let filteredComments = comments
  if (options.onlyNew !== undefined) {
    if (options.onlyNew) {
      filteredComments = filteredComments.filter((comment) => comment.new)
    } else {
      filteredComments = filteredComments.filter((comment) => !comment.new)
    }
  }

  if (options.onlyMyMessages !== undefined) {
    const filterByMe = options.onlyMyMessages.filterByMe
    const userName = options.onlyMyMessages.userName

    if (!userName || filterByMe === undefined) {
      throw new Error(
        'When filtering comments by user, both userName and filterByMe must be provided',
      )
    }

    let patternForFilteringByUserName = userName
    if (userName.includes('@clearchannel.com')) {
      patternForFilteringByUserName = '@clearchannel.com'
    }

    if (filterByMe) {
      filteredComments = filteredComments.filter((comment) =>
        comment.created_by.includes(patternForFilteringByUserName),
      )
    } else {
      filteredComments = filteredComments.filter(
        (comment) =>
          !comment.created_by.includes(patternForFilteringByUserName),
      )
    }
  }

  return filteredComments
}

export function getFetchedQuickPlansComments(): Comment.QuickAccessComment[] {
  return getters(MODULE_NAME_PLAN, GETTERS.GET_PLANS_COMMENTS)
}

/**
 * This function will remove the element from the quickPlansComments array and return it.
 * Which means that is mutating the quickPlansComments array passed by parameters.
 */
function getElementFromQuickAcccessCommentsData(
  quickPlansComments: Comment.QuickAccessComment[],
  planId: string,
): Comment.QuickAccessComment | undefined {
  const index = quickPlansComments.findIndex(
    (comment) => comment.planId === planId,
  )
  if (index === -1) {
    return undefined
  } else {
    const element = quickPlansComments[index]
    quickPlansComments.splice(index, 1)
    return element
  }
}

function isPlanInLatestPlansToFetchComments(
  latestPlans: Plan.PlanForCommentFetching[],
  planId: string,
): boolean {
  return latestPlans.some((plan) => plan.planId === planId)
}

export async function updateQuickAcessPlansComments(): Promise<void> {
  console.debug('📃 🤚 Updating quick access plans comments')
  const meta = getAppMetadata()
  const environment = BrowserEnvironmentResolver.getInstance()

  const lastUpdateAt: Date | null = getters(
    MODULE_NAME_PLAN,
    GETTERS.GET_PLANS_COMMENTS_LAST_UPDATE,
  )
  // const availablePlans: Plan.PlanForCommentFetching[] = [
  //   { planId: 'H0Ucn4d-RD75qryHo1OY8', planName: 'ttt', createdBy: 'inavarro+fi1@cartodb.com' },
  //   { planId: '4-_OE0w5ob4g1npzAfMZR', planName: 'vicente', createdBy: 'inavarro+fi1@cartodb.com' },
  // ]
  const availablePlans: Plan.PlanForCommentFetching[] =
    getFetchedPlansForComments()
  if (availablePlans.length === 0) {
    console.debug('📃 ✅ No plans available, early return')
    return
  }
  // const quickPlansComments: Comment.QuickAccessComment[] = [
  //   {
  //     planName: 'ttt',
  //     planId: 'H0Ucn4d-RD75qryHo1OY8',
  //     comments: 3,
  //     latestComment: new Date('2024-11-28T14:48:30.712Z'),
  //     created_by: 'inavarro+fi1@cartodb.com',
  //   },
  //   {
  //     planName: 'vicente',
  //     planId: '4-_OE0w5ob4g1npzAfMZR',
  //     comments: 2,
  //     latestComment: new Date('2024-11-28T14:48:30.712Z'),
  //     created_by: 'inavarro+fi1@cartodb.com',
  //   },
  // ]
  const quickPlansComments: Comment.QuickAccessComment[] =
    getFetchedQuickPlansComments()

  // Get the latest comments and check if they are in the available plans
  const latestPlanComments = await CommentsService.getLatestPlanComments(
    meta,
    environment,
  )

  // Lets see if we have to do an early return
  if (latestPlanComments.length === 0) {
    console.debug('📃 ✅ No comments found, early return')
    return
  }

  let latestPlansVisibleByUserForCommentFetching: Plan.PlanForCommentFetching[] =
    [...availablePlans]
  if (quickPlansComments.length > 0) {
    // Only manage those updated plans visible by the user
    const latestPlanCommentsVisibleByUser = latestPlanComments.filter(
      (subscribedPlanComment) => {
        const isVisibleByUser = availablePlans.some(
          (plan) => plan.planId === subscribedPlanComment.planId,
        )
        return isVisibleByUser
      },
    )
    latestPlanCommentsVisibleByUser.sort(
      (a, b) => (b.created_at?.getTime() ?? 0) - (a.created_at?.getTime() ?? 0),
    )
    console.debug(
      '📃 Comments for the following plans need to be fetched: ',
      latestPlanCommentsVisibleByUser,
    )

    // After filtering only plans visible by the user, check if the latest comment is older than the last update
    if (latestPlanCommentsVisibleByUser.length === 0) {
      console.debug('📃 ✅ No plans need to be refeched')
      return
    }

    const mostRecentComment = latestPlanCommentsVisibleByUser[0].created_at
    if (!mostRecentComment) {
      console.error(
        '📃 Expected created_at value from latestComments, but no value found',
      )
      throw new Error(
        'Expected created_at value from latestComments, but no value found',
      )
    }

    if (lastUpdateAt && mostRecentComment && mostRecentComment < lastUpdateAt) {
      console.debug(
        `📃 ✅ Latest comment fetched is from ${mostRecentComment.toISOString()} and latest update was done at ${lastUpdateAt.toISOString()}`,
      )
      return
    }

    commit(
      MODULE_NAME_PLAN,
      MUTATIONS.SET_LATEST_PLANS_COMMENTS_UPDATED,
      latestPlanCommentsVisibleByUser.map((plan) => plan.planId),
    )

    latestPlansVisibleByUserForCommentFetching = availablePlans.filter(
      (plan) => {
        return latestPlanCommentsVisibleByUser.some(
          (latestPlan) => plan.planId === latestPlan.planId,
        )
      },
    )
  }

  // Get all the comments for the updated plans visible by the user
  const newQuickPlansComments = await CommentsService.getAllNewComments(
    meta,
    environment,
    latestPlansVisibleByUserForCommentFetching,
  )
  console.debug(
    '📃 New quick plans comments retreived: ',
    newQuickPlansComments,
  )

  // Merge updated plan comments with the existing ones
  const updatedQuickPlansComments: Comment.QuickAccessComment[] = []

  if (lastUpdateAt !== null) {
    quickPlansComments.forEach((planComment) => {
      const planId = planComment.planId
      if (
        !isPlanInLatestPlansToFetchComments(
          latestPlansVisibleByUserForCommentFetching,
          planId,
        )
      ) {
        updatedQuickPlansComments.push(planComment)
      } else {
        const updatedQuickPlanComments = getElementFromQuickAcccessCommentsData(
          newQuickPlansComments,
          planId,
        )
        if (updatedQuickPlanComments) {
          updatedQuickPlansComments.push(updatedQuickPlanComments)
        }
      }
    })
  }
  updatedQuickPlansComments.push(...newQuickPlansComments)

  // Sort the comments by the latest comment date decreasing
  updatedQuickPlansComments.sort(
    (a, b) => b.latestComment.getTime() - a.latestComment.getTime(),
  )

  commit(MODULE_NAME_PLAN, MUTATIONS.SET_PLANS_COMMENTS_LAST_UPDATE, new Date())
  console.debug(
    '📃 ✅ Quick comments updated with data: ',
    updatedQuickPlansComments,
  )
  commit(
    MODULE_NAME_PLAN,
    MUTATIONS.SET_PLANS_COMMENTS,
    updatedQuickPlansComments,
  )
}

export async function subscribeToComments(
  userId: string,
  meta: Metadata.AppMetadata,
  environment: BrowserEnvironmentResolver,
): Promise<void> {
  if (!isQuickAccessCommentEnabled(meta)) {
    return
  }
  const isSubscribed = getters(
    MODULE_NAME_PLAN,
    GETTERS.GET_IS_SUBSCRIBED_TO_COMMENTS,
  )
  if (isSubscribed) {
    console.warn(
      'Already subscribed to comments. This should be not happening, please check how this is being called',
    )
    console.debug('📃 ✋ Already subscribed to comments. Nothing to do')
    return
  }
  console.debug('📃📃📃📃 Subscribing to comments 📃📃📃📃')
  const debounceDelay = getRealtimeReactionForQuickAccessComments(meta)
  console.debug('📃 Debounce delay for comments in seconds: ', debounceDelay)
  const unscribeFunction = await subscribeToCommentsService(
    meta,
    environment,
    debounceDelay,
    updateQuickAcessPlansComments,
  )
  window.addEventListener('beforeunload', () => {
    if (unscribeFunction) {
      console.debug('📃 ✅ Unsubscribing from comments before closing')
      unscribeFunction()
      commit(MODULE_NAME_PLAN, MUTATIONS.SET_IS_SUBSCRIBED_TO_COMMENTS, false)
    }
  })
  commit(MODULE_NAME_PLAN, MUTATIONS.SET_IS_SUBSCRIBED_TO_COMMENTS, true)
}
