import { Environment } from '@workspaces/types'

// prettier-ignore
export default class BrowserEnvironmentResolver implements Environment.EnvironmentResolver {
  private readonly environment: string | undefined
  private readonly testing: string | undefined
  private readonly sharePlan: Environment.SharePlan

  private static instance: Environment.EnvironmentResolver

  private isSharedPlan(): Environment.SharePlan {
    try {
      const url = new URL(window.location.href)
      if (url.searchParams.has('shared')) {
        const shared = url.searchParams.get('shared')
        if (shared === 'true') {
          return { sharing: true, id: url.searchParams.get('planId') ?? 'unable_to_retreive' }
        }
      }
    } catch (error) {
      console.warn('Error parsing URL. Assuming no shared plan')
    }
    return { sharing: false, id: '' }
  }

  private constructor() {
    this.environment = process.env.VUE_APP_ENV
    this.testing = process.env.VUE_APP_TESTING
    this.sharePlan = this.isSharedPlan()
  }

  getLocation(): string {
    throw new Error('BrowserEnvironmentResolver has no location')
  }

  public static getInstance(): Environment.EnvironmentResolver {
    if (!BrowserEnvironmentResolver.instance) {
      BrowserEnvironmentResolver.instance = new BrowserEnvironmentResolver()
    }

    return BrowserEnvironmentResolver.instance
  }

  getEnvironment(): string {
    switch (this.environment) {
      case 'development':
        return Environment.Environment.Development
      case 'staging':
        return Environment.Environment.Staging
      case 'production':
        return Environment.Environment.Production
      default:
        throw new Error(
          `Unknown environment for value [${this.environment}]. Check .env file and VUE_APP_ENV variable`,
        )
    }
  }

  getEnvironmentForMetadata(): string {
    switch (this.environment) {
      case 'development':
        return 'dev'
      case 'staging':
        return 'staging'
      case 'production':
        return 'prod'
      default:
        throw new Error(
          `Unknown environment for value [${this.environment}]. Check .env file and VUE_APP_ENV variable`,
        )
    }
  }

  getEnvironmentForBQ(): string {
    const environment = this.getEnvironment()
    return this.isTesting() ? `${environment}_test` : environment
  }

  getEnvironmentVariable(name: string): string | undefined {
    const environmentVaraibleValue = process.env[name]
    if (environmentVaraibleValue) {
      return environmentVaraibleValue
    }
    throw new Error(`Environment variable ${name} not found`)
  }

  isTesting(): boolean {
    if (this.testing === undefined) {
      console.warn(
        'VUE_APP_TESTING environment variable not found. Assuming false',
      )
    }
    return this.testing === 'true'
  }

  isSharePlan(): { sharing: boolean; id: string } {
    return this.sharePlan
  }
}
