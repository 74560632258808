import { AudiencesQuintile, Environment, Metadata } from '@workspaces/types'
import {
  AudiencesQuintileBrowserService,
  AudiencesQuintileService,
} from '@workspaces/services'
import { getAudiencesQuintileFixedValuesOnSort } from '@/plan/metadata/metadata.helper'
import SharedPlanPasswordManager from '@/helpers/sharedPlanPassword.helper'
import sharedPlanService from './sharedPlan.service'

function formatQuintileAudiences(
  rawAudiences: AudiencesQuintile.Option[],
): AudiencesQuintile.AudienceParentBrowser[] {
  const audienceSeprator = '>>'
  const audienceMap: {
    [key: string]: AudiencesQuintile.AudienceParentBrowser
  } = {}

  rawAudiences.forEach((audience) => {
    const [parentName, audienceName] = audience.name
      .split(audienceSeprator)
      .map((s) => s.trim())

    if (!audienceMap[parentName]) {
      audienceMap[parentName] = {
        name: parentName,
        audiences: [],
        collapsed: true,
      }
    }

    audienceMap[parentName].audiences.push({ name: audienceName })
  })

  const result = Object.values(audienceMap)

  // Sorting children by name
  result.forEach((parent) => {
    parent.audiences.sort((a, b) => a.name.localeCompare(b.name))
  })

  return result
}

function sortAudiences(
  fixedValues: { beginning: string[]; end: string[] },
  audiences: AudiencesQuintile.AudienceParentBrowser[],
): AudiencesQuintile.AudienceParentBrowser[] {
  const sortedAudiences: AudiencesQuintile.AudienceParentBrowser[] = []
  const audiencesSortedAlpahbetically = [...audiences]
  const { beginning, end } = fixedValues
  const audienesAtTheEnd: AudiencesQuintile.AudienceParentBrowser[] = []

  // Sort by alphabetical order
  audiencesSortedAlpahbetically.sort((a, b) => a.name.localeCompare(b.name))

  // Add fixed values to the beginning
  beginning.forEach((name) => {
    const index = audiencesSortedAlpahbetically.findIndex(
      (audience) => audience.name === name,
    )
    if (index !== -1) {
      sortedAudiences.push(audiencesSortedAlpahbetically[index])
      audiencesSortedAlpahbetically.splice(index, 1)
    }
  })

  // Prepare fixed values to append at the end
  end.forEach((name) => {
    const index = audiencesSortedAlpahbetically.findIndex(
      (audience) => audience.name === name,
    )
    if (index !== -1) {
      audienesAtTheEnd.push(audiencesSortedAlpahbetically[index])
      audiencesSortedAlpahbetically.splice(index, 1)
    }
  })

  sortedAudiences.push(...audiencesSortedAlpahbetically)
  sortedAudiences.push(...audienesAtTheEnd)

  return sortedAudiences
}

export default {
  async getAudiencesQuintileOptions(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    params: AudiencesQuintile.Params,
  ): Promise<AudiencesQuintile.AudienceParentBrowser[]> {
    const data: AudiencesQuintile.Option[] =
      await AudiencesQuintileBrowserService.getAudiencesQuintileOptions(
        meta,
        environment,
        params,
      )

    const dataFormatted = formatQuintileAudiences(data)
    const fixedValues = getAudiencesQuintileFixedValuesOnSort(meta)
    const dataSorted = sortAudiences(fixedValues, dataFormatted)

    return dataSorted
  },
  async getZipCodesByQuintile(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    params: AudiencesQuintile.RunFilterParams,
    planId: string,
  ): Promise<Map<number, AudiencesQuintile.ZipCodeInfo>> {
    let data: AudiencesQuintile.ZipCodesByQuintile[]
    const isSharePlan = environment.isSharePlan().sharing
    if (isSharePlan) {
      const password = SharedPlanPasswordManager.getInstance().getPassword()
      data = await sharedPlanService.getSharedPlanAudiencesQuintiles(
        meta,
        environment,
        planId,
        password,
      )
    } else {
      data = await AudiencesQuintileService.getZipCodesByQuintile(
        meta,
        environment,
        params,
        planId,
      )
    }

    const dataFormatted = new Map<number, AudiencesQuintile.ZipCodeInfo>()
    data.forEach((item) => {
      dataFormatted.set(item.zipCode, {
        quintile: item.quintile,
        value: item.value,
      })
    })
    return dataFormatted
  },
}
