// Specific delimiter as customer uses symbols in their data
export const DELIMITER = ',#$'
export const BREAKLINE = '\n'

export const TEMPLATE_LABELS = {
  planTitleLabel: '#plan_title_label#',
  planTitleValue: '#plan_title_value#',
  totalPanelsLabel: '#total_panels_label#',
  totalPanelsValue: '#total_panels_value#',
  downloadTimeLabel: '#download_time_label#',
  downloadTimeValue: '#download_time_value#',
  headers: '#headers#',
  data: '#data#',
}
