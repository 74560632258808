export enum Environment {
  Development = 'dev',
  Production = 'pro',
  Staging = 'stg',
  Local = 'local',
}

export type SharePlan = { sharing: boolean; id: string }

export interface EnvironmentResolver {
  getEnvironment(): string

  getEnvironmentForMetadata(): string

  getEnvironmentVariable(name: string): string | undefined

  isTesting(): boolean

  getLocation(): string

  getEnvironmentForBQ(): string

  isSharePlan(): SharePlan
}
