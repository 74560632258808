export function escapeString(str: string, lowercase = false): string {
  const rawStr = lowercase ? str.toLocaleLowerCase() : str
  return rawStr
    .trim()
    .replaceAll("'", "\\'")
    .replaceAll('`', '\\`')
    .replaceAll('´', "\\'")
}

function isStringStartingAndEndingWith(
  escapedChars: string[],
  str: string,
): boolean {
  return escapedChars.some((char) => str.startsWith(char) && str.endsWith(char))
}

export function removeWordDelimiters(entry: string[]): string[] {
  const escapedChars = ['"']
  const formattedEntry = entry.map((e) => {
    if (isStringStartingAndEndingWith(escapedChars, e)) {
      return e.slice(1, e.length - 1)
    }
    return e
  })

  return formattedEntry
}

export function removeUnwantedCharsForCVS(content: string): string {
  const lineBreakers = ['\n', '\r', '\r\n']
  let contentFormatted = content.toString()
  lineBreakers.forEach((lineBreaker) => {
    contentFormatted = contentFormatted.replaceAll(lineBreaker, ' ')
  })
  return contentFormatted
}
