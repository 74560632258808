import { Asset } from '@workspaces/types'

export function analyzeAssetsForAllDigitalOrAllClassic(
  assets: Asset.GeoJSONAsset[],
): { allDigital: boolean; allStatic: boolean } {
  const allDigital = assets.every((asset) => asset.properties.is_digital)

  if (allDigital) {
    return { allDigital, allStatic: false }
  }

  const allStatic = assets.every((asset) => !asset.properties.is_digital)
  return { allDigital: false, allStatic }
}
