import { AssetFilterGeoboundaries } from './geoboundary.type'
import { FilterByPoisParamsRequest } from './poi.type'
import { FeatureCollection } from 'geojson'

export interface exportAssetsAudienceRequest {
  delivery: string
  index: number | number[]
  asset_ids: string[]
  audience_ids: string[]
  countries?: number[]
}

export interface audienceData {
  class: string
  index: number
}

export interface exportAssetAudience {
  asset_id: string
  audiences_values: audienceData[]
}

export interface exportAssetsPOIsRequest extends FilterByPoisParamsRequest {
  asset_ids: string[]
  geoboundaries?: AssetFilterGeoboundaries
  polygonGeom?: FeatureCollection
}

export interface exportAssetPOI {
  asset_id: string
  id: string
  name: string
  distance: number
  address: string
  zip_code: string
  city: string
}

export interface exportBasicPOI {
  id: string
  name: string
  address: string
  zip_code: string
  city: string
}

export interface exportAudienceLastDate {
  lastDate: string
}

export enum FilePOIColumns {
  Id = 'EXPORT_POI_ID',
  Name = 'EXPORT_POI_NAME',
  Distance = 'EXPORT_POI_DISTANCE',
  Address = 'EXPORT_POI_ADDRESS',
  ZipCode = 'EXPORT_POI_POSTAL_CODE',
  City = 'EXPORT_POI_CITY',
}

export enum POIsPartialQueryAim {
  GeoboundariesWithOutGeometry = '',
  GeoboundariesWithGeometry = '_geoboundaries_with_geometry',
  CustomGeoboundaries = '_custom_geoboundaries',
}

export enum CTEsNameForPOIsGrouping {
  GeoboundaryWithOutGeometry = `all_pois`,
  GeoboundaryWithGeometry = `regions_geoboundaries_with_geometry`,
  CustomGeoboundaries = `regions_custom_geoboundaries`,
  CombinateGeoboundaries = 'union_of_all_pois',
}

export interface ExportAssetsPOIsDistance {
  internalPanelId: string
  externalPanelId: string
  poiId: string
  poiName: string
  poiAddress: string
  poiCity: string
  poiPostalCode: string
  distance: number
}

export enum XlsxTab {
  Assets = 'assets',
  POIs = 'pois',
  Proximity = 'proximity',
}

export const HeaderColumnsForPOIsTab: string[] = [
  'poi_id',
  'poi_name',
  'poi_address',
  'poi_postal_code',
  'poi_city',
  'assets_within_distance',
]

export const HeaderColumnsForAdvancedPOIsTab: string[] = [
  'internal_panel_id',
  'external_panel_id',
  'poi_id',
  'poi_name',
  'poi_address',
  'poi_postal_code',
  'poi_city',
  'distance_to_poi',
]
