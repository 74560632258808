import { Environment, Metadata, Package } from '@workspaces/types'
import { CacheManager, PackageBrowserService } from '@workspaces/services'
import { getCacheInforForModel } from '@/plan/metadata/metadata.helper'
import { getFromIndexDb, putToIndexDb } from '@/cache/indexDb.helper'
import PackagesService from '@/services/packages.service'

async function getDataFromIndexDb(
  model: Metadata.DataModelBase,
  loggingTag = '📌',
): Promise<Package.Package[] | null> {
  const {
    dbName,
    store,
    key,
    key_last_update: keyLastUpdate,
    version,
  } = getCacheInforForModel(model)
  const dataLastUpdateInCache = await getFromIndexDb(
    dbName,
    version,
    store,
    keyLastUpdate,
  )
  const cacheManagerDataLastUpdate =
    CacheManager.getInstance().getCacheInfoForTable(model.table_name)
  console.debug(
    `${loggingTag} ${key} last update in cache ${dataLastUpdateInCache} and in cacheManager ${cacheManagerDataLastUpdate}`,
  )
  if (dataLastUpdateInCache === cacheManagerDataLastUpdate) {
    console.debug(
      `${loggingTag} ${key} content in cache is up-to-date. Getting data from cache`,
    )
    const data = await getFromIndexDb(dbName, version, store, key)
    if (data !== null && Array.isArray(data) && data.length > 0) {
      console.debug(`${loggingTag} ${key} found in indexDb`)
      return data
    }
  }
  return null
}

async function saveContentToCache(
  model: Metadata.DataModelBase,
  data: unknown[],
  loggingTag = '📌',
): Promise<void> {
  const {
    dbName,
    store,
    key,
    key_last_update: keyLastUpdate,
    version,
  } = getCacheInforForModel(model)
  const lastUpdate = CacheManager.getInstance().getCacheInfoForTable(
    model.table_name,
  )
  console.debug(`${loggingTag}Saving packages to cache`)
  await putToIndexDb(dbName, version, store, keyLastUpdate, lastUpdate)
  await putToIndexDb(dbName, version, store, key, data)
  console.debug(`     ${loggingTag}    Packages saved to cache`)
}

export async function getPackages(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): Promise<Package.Package[]> {
  const model = meta.data_model.packages
  let data = await getDataFromIndexDb(model)

  if (data === null) {
    data = await PackagesService.getAllPackages(meta, environment)
    await saveContentToCache(model, data)
  }

  if (environment.getEnvironment() !== Environment.Environment.Production) {
    console.debug('Retrieved packages', data)
  }

  return data
}

export async function getPackageAvailability(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  startDate: string,
  endDate: string,
  packageId: number,
): Promise<number> {
  const data: number = await PackagesService.getPackageAvailability(
    meta,
    environment,
    startDate,
    endDate,
    packageId,
  )
  return data
}

export async function getAllPackagesWithAllProperties(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  params: Package.PackageFilterData,
): Promise<Package.PackageTableData[]> {
  const data: Package.PackageTableData[] =
    await PackageBrowserService.getAllPackagesWithAllProperties(
      meta,
      environment,
      params,
    )
  return data
}
